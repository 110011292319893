import { useState, useEffect } from 'react'
import {env} from '../../../environment'
import { getHash } from '../../utils/Crypto'

export const useWishlistData = (options: any) => {
  const [wishlistData, setWishlistData] = useState(undefined)

  let signature = getHash(options)

  useEffect(() => {
    fetchWishlistData(options).then(response => {
      if (response.error > 0) {
        setWishlistData({ error: response.data })
      } else {
        setWishlistData(response.data)
      }
    })
  }, [signature])

  return wishlistData
}

export const fetchWishlistData = (options: any) => {
  let url =
    env.getServiceHost() + 'favorites/wishlist?version=1&code=' + options.code

  return fetch(url).then(response => response.json())
}

export const fetchWishlistCode = (options: any) => {
  let url =
    env.getServiceHost() +
    'favorites/sendwishlistcode?version=1&email=' +
    options.mail +
    '&country=' +
    env.getCountry()

  return fetch(url).then(response => response.json())
}
